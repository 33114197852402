import './WhatsNext.scss';
import { IWhatsNextProps } from './WhatsNext.model';
import Paragraph from '../../common/components/paragraph/Paragraph';


export default function WhatsNext(props: IWhatsNextProps) {

  return (
    <>
    <div className="container">
			<div className='flex flex-wrap lg:flex-nowrap items-stretch justify-center 
				h-full p-10 sm:p-20 lg:p-28 lg:pl-40 font-semibold lg:space-x-16'>
					<div className='lg:basis-5/12 lg:mb-0 self-center lg:self-center'>
					<Paragraph title="What's Next">
							<div className=''>
								If you have a question or just want to say Hi, my inbox is always open. 
								<br></br>
								<br></br>
								Go ahead and send me an email!
							</div>
					</Paragraph>
					</div>
					<div className='lg:basis-6/12 flex flex-col space-y-10 items-center self-start lg:self-center'>
						<div className='text-3xl sm:text-5xl md:text-6xl lg:text-6xl xl:text-7xl font-extrabold'>
							Get In Touch
						</div>
						<a className="btn btn-clipped text-lg sm:text-xl md:text-3xl lg:text-xl" 
							href="mailto:marco.b@hotmail.it" rel="noopener noreferrer" target="_blank">
								Say Hello
						</a>
					</div>
					
			</div>
			<div className="absolute bottom-0 left-0 right-0 p-10 text-xs sm:text-base ml-auto mr-auto w-full
				md:text-base lg:text-base text-slate-400 text-center">
				{"Design & Built by Marco Battisti"} 
			</div>
    </div>
    </>
  )
 }