import  { useState } from 'react'
import './Experience.scss';
import { IExperienceProps } from './Experience.model';
import BigTitle from '../../common/components/bigTitle/BigTitle';
import Paragraph from '../../common/components/paragraph/Paragraph';

export default function Experience (props: IExperienceProps) {
  const [activeExperience, setActiveExperience] = useState(0)
  return (
    <>
    <div className="container">
      <BigTitle title='Experience'></BigTitle>
      <div className='flex flex-col flex-nowrap h-[50vh] mt-[20%] lg:mt-[10%] p-10 sm:p-20 lg:p-28 lg:pl-40'>
        <Paragraph title='EXPERIENCE' titleClasses={["self-start"]}>
          <div className='flex flex-wrap lg:flex-nowrap lg:space-x-9 mt-2 sm:mt-10'>
            <div className='flex flex-row lg:flex-col lg:basis-2/12 overflow-x-scroll lg:overflow-x-hidden 
              touch-pan-x pb-4'>
                {
                  props.experiences.map((experience, index) => (
                    <div key={"experience-comp-name-"+index} 
                      className={'lg:cursor-pointer p-2 w-24 sm:w-40 lg:w-52 shrink-0 lg:mr-7 '
                      .concat('lg:mb-10 text-xs sm:text-lg md:text-xl text-center lg:text-left ')
                      .concat(activeExperience === index ? 
                          'border-b-2 border-b-slate-500 font-semibold' 
                          : ' text-slate-600	font-medium')}
                        onClick={() => setActiveExperience(index)}>
                      {experience.companyName.substring(0, 8).toUpperCase()}
                    </div>
                  ))
                }
            </div>
            <div className='lg:basis-10/12 text-left'>
              {
                  props.experiences.map((experience, experienceIndex) => (
                    <>
                    <div key={"experience-"+experienceIndex} 
                      className={activeExperience === experienceIndex ? '' : ' hidden'}>
                      <div className='mb-2 text-base sm:text-4xl lg:text-2xl xl:text-4xl font-semibold' >
                        {experience.role} @ {experience.companyName}
                      </div>
                      <div className='mb-5 text-xs sm:text-lg md:text-2xl lg:text-base font-normal text-slate-500'>
                        {getExperienceDatesInterval(experience.from, experience.to)}
                      </div>
                      <div className='md:ml-4 text-sm sm:text-lg md:text-2xl lg:text-lg 
                          font-medium text-slate-500 '>
                        <ul>
                          {
                            experience.responsibilities.map((responsibility, respIndex) => (
                              <li key={"experience-resp-"+respIndex} 
                                className='responsibility leading-6 md:leading-8 ml-4 mb-2 md:mb-4'>
                                {responsibility}
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                    </>
                  ))
                }
            </div>
          </div>
        </Paragraph>
      </div>
    </div>
    </>
  )
}

function getExperienceDatesInterval(from: Date, to?: Date) {
  return getMonthAndYearLabel(from) + " - " + (to ? getMonthAndYearLabel(to) : "Present");
}

function getMonthAndYearLabel(date: Date) {
  return getMonthLabelShort(date).toLocaleUpperCase() +  " " + date.getFullYear();
}

function getMonthLabelShort(date: Date) {
  return date.toLocaleString('default', { month: 'short' });
}
