import React, { useRef, useEffect, MutableRefObject } from 'react'
import { CanvasDraw, ICanvasProps, IUseCanvasOptions } from './Canvas.model';

let animationFrameId: number = 0;

export default function Canvas(props: ICanvasProps) {
	const { draw, isVisible, options, className, ...rest } = props
	const { context } = options;

	const predraw = (context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
		context.save();
		resizeCanvasToDisplaySize(context, canvas);
		const { width, height } = context.canvas;
		context.clearRect(0, 0, width, height);
	}

  	const canvasRef = useCanvas(draw, {context, predraw});

	if(!isVisible){
		window.cancelAnimationFrame(animationFrameId);
		return (<></>);
	}
  
  return <canvas ref={canvasRef} className={className} {...rest}/>
}


const useCanvas = (draw: CanvasDraw, options: IUseCanvasOptions): 
	MutableRefObject<HTMLCanvasElement | null> => {
		
	const canvasRef = useRef<HTMLCanvasElement | null>(null)
  
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext(options.context || '2d');
    if(canvas && context){

			const render = (t: DOMHighResTimeStamp) => {
				options.predraw(context, canvas);
				draw(context, t);
				context.restore();
				animationFrameId = window.requestAnimationFrame(render);
			}
			render(0);

			
			return () => {
				window.cancelAnimationFrame(animationFrameId)
			}
    }
  }, [draw, options, options.context, options.predraw]);
	
	return canvasRef;
}  

function resizeCanvasToDisplaySize(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
	//const { width, height } = canvas.getBoundingClientRect()
	const width = window.innerWidth;
	const height = window.innerHeight;

	ctx.canvas.style.width = window.innerWidth+"px";
  	ctx.canvas.style.height = window.innerHeight+"px";

	if (canvas.width !== width || canvas.height !== height) {
		canvas.width = width;
		canvas.height = height;
		return true;
	}

	return false
}
