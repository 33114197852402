import { IExperiencesType } from "../common/models/IExperience";

export function getExperiences(): IExperiencesType {
    return [
        {
          companyName: 'IBM',
          from: new Date('2021-09-07'),
          to: undefined,
          role: 'Senior Software Engineer',
          responsibilities: [
            'Working to develop microservices that communicate with external services and IBM Watson Assistant AI',
            'Working to create real time dashboards to manage and visualize large amount of data',
            'Working to create toolchain solutions to simplify code delivery and to increase code quality reaching high standards'
          ]
        },
        {
          companyName: 'GFT Technologies',
          from: new Date('2020-04-03'),
          to: new Date('2021-09-06'),
          role: 'Software Engineer',
          responsibilities: [
            'Working to develop front-end and back-end solutions for several clients',
            'Working to create microservices integrated with IBM Watson Assistant AI and Google Cloud Dialogflow'
          ]
        },
        {
          companyName: 'Hermes',
          from: new Date('2017-12-17'),
          to: new Date('2020-04-02'),
          role: 'Software Engineer',
          responsibilities: [
            'Working to create microservices to manage financial data',
            'Working to develop advanced financial algorithms',
            'Working with SonarCube and Jenkins'
          ]
        }
    ];
}