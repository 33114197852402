
import { gsap, Power4 } from "gsap";
import { MutableRefObject} from 'react';

const BACKGROUND_ON_OPEN = "black";
const BACKGROUND_ON_CLOSE = "#318b99";

export const addOpenMenuAnimation = (tlOpen: MutableRefObject<gsap.core.Timeline | null>, 
    navbarContainer: MutableRefObject<HTMLDivElement | null>, 
    innerContainer: MutableRefObject<HTMLDivElement | null>, 
    closeTrigger: MutableRefObject<HTMLDivElement | null>, 
    openTrigger: MutableRefObject<HTMLDivElement | null>) => {
  
      const innerContainerSelector = gsap.utils.selector(innerContainer);
      const openTriggerSelector = gsap.utils.selector(openTrigger);
      const closeTriggerSelector = gsap.utils.selector(closeTrigger);
  
      tlOpen.current?.add("preOpen")
      .to(navbarContainer.current, {
        duration: 0.1, height: "100vh", delay: 0.1, ease: Power4.easeIn, 
      })
      .to(openTriggerSelector(".menu-trigger-bar.top"), {
        duration: 0.4, x: "+80px", y: "-80px", delay: 0.1, ease: Power4.easeIn, 
        onComplete: function() {
          if(closeTrigger.current && closeTrigger.current.style) {
            closeTrigger.current.style.zIndex = '25';
          }
        }
      }, "preOpen")
      .to(openTriggerSelector(".menu-trigger-bar.middle"), {
        duration:0.4, x: "+=80px", y: "-=80px", ease: Power4.easeIn,
        onComplete: function() {
          if(openTrigger.current && openTrigger.current.style) {
            openTrigger.current.style.visibility = 'hidden';
          }
        }
      }, "preOpen")
      .to(openTriggerSelector(".menu-trigger-bar.bottom"), {
        duration: 0.4, x: "+=80px", y: "-=80px", delay: 0.2, ease: Power4.easeIn
      }, "preOpen")
      .add("open", "-=0.4")
      .to(innerContainer.current, {
        duration: 0.8, backgroundColor: BACKGROUND_ON_OPEN, ease: Power4.easeInOut
      }, "open")
      .to(innerContainerSelector(".menu-bg.top"),  {
        duration: 0.8, x: "0", y: "0", ease: Power4.easeInOut
      }, "open")
      .to(innerContainerSelector(".menu-bg.middle"), {
        duration: 0.8, x: "0", y: "0", ease: Power4.easeInOut
      }, "open")
      .to(innerContainerSelector(".menu-bg.bottom"), {
        duration: 0.8, x: "0", y: "0", ease: Power4.easeInOut
      }, "open")
      .fromTo(innerContainerSelector(".menu-container .menu"), {
        duration: 0.6,  y: 30, opacity: 0, visibility: 'hidden'
      }, {
        duration: 0.6, delay:0.8, y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut
      }, "open")
      .add("preClose", "-=0.8")
      .to(closeTriggerSelector(".close-trigger-bar.left"), {
        duration: 0.8, x: 0, y: 0, ease: Power4.easeOut
      }, "preClose")
      .to(closeTriggerSelector(".close-trigger-bar.right"), {
        duration: 0.8, x: 0, y: 0, delay: 0.2, ease: Power4.easeOut
      }, "preClose");
  }
  
export const addCloseMenuAnimation = (tlClose: MutableRefObject<gsap.core.Timeline | null>, 
    navbarContainer: MutableRefObject<HTMLDivElement | null>, 
    innerContainer: MutableRefObject<HTMLDivElement | null>, 
    closeTrigger: MutableRefObject<HTMLDivElement | null>, 
    openTrigger: MutableRefObject<HTMLDivElement | null>,
    menu: MutableRefObject<HTMLUListElement | null>,
    menuTop: MutableRefObject<HTMLElement | null>,
    menuMiddle: MutableRefObject<HTMLElement | null>,
    menuBottom: MutableRefObject<HTMLElement | null>) => {
  
    const innerContainerSelector = gsap.utils.selector(innerContainer);
    const openTriggerSelector = gsap.utils.selector(openTrigger);
    const closeTriggerSelector = gsap.utils.selector(closeTrigger);
  
    tlClose.current?.add("close")
    .to(innerContainerSelector(".menu-bg.top"), {
      duration: 0.2, backgroundColor: BACKGROUND_ON_CLOSE, ease: Power4.easeInOut, 
      onComplete: function() {
        if(openTrigger.current && openTrigger.current.style) {
          openTrigger.current.style.visibility = 'visible';
        }
        if(closeTrigger.current && closeTrigger.current.style) {
          closeTrigger.current.style.zIndex = '5';
        }
      }
    }, "close")
    .to(innerContainerSelector(".menu-bg.middle"), {
      duration: 0.2, backgroundColor: BACKGROUND_ON_CLOSE, ease: Power4.easeInOut
    }, "close") 
    .to(innerContainerSelector(".menu-bg.bottom"), {
      duration: 0.2, backgroundColor: BACKGROUND_ON_CLOSE, ease: Power4.easeInOut
    }, "close")
    .to(innerContainer.current, {
      duration: 0.2, backgroundColor: BACKGROUND_ON_CLOSE, ease: Power4.easeInOut
    }, "close")
    .to(innerContainerSelector(".menu-container .menu"), {
      duration: 0.6, y: 20, opacity: 0, ease: Power4.easeOut, 
      onComplete: function() {
        if(menu && menu.current && menu.current.style) {
          menu.current.style.visibility = 'hidden';
        }
      }
    }, "close")
    .add("afterClose")
    .to(innerContainer.current, {
      duration: 0.8, backgroundColor: "transparent", ease: Power4.easeInOut
    }, "open")
    .to(innerContainerSelector(".menu-bg.top"), {
      duration: 0.8, y: "-100%", ease: Power4.easeInOut
    }, "afterClose")
    .to(innerContainerSelector(".menu-bg.middle"), {
      duration: 0.8, scaleY: 0, ease: Power4.easeInOut
    }, "afterClose")
    .to(innerContainerSelector(".menu-bg.bottom"), {
      duration: 0.8, y: "100vh", ease: Power4.easeInOut,
      onComplete: function() {
        if(menuTop && menuTop.current && menuTop.current.style
          && menuMiddle && menuMiddle.current && menuMiddle.current.style
          && menuBottom && menuBottom.current && menuBottom.current.style) {
            menuTop.current.style.backgroundColor = BACKGROUND_ON_OPEN;
            menuMiddle.current.style.backgroundColor = BACKGROUND_ON_OPEN;
            menuBottom.current.style.backgroundColor = BACKGROUND_ON_OPEN;
        }
      }
    }, "afterClose")
    .to(closeTriggerSelector(".close-trigger-bar.left"), {
      duration: 0.2, x: 100, y: -100, ease: Power4.easeIn
    }, "close")
    .to(closeTriggerSelector(".close-trigger-bar.right"), {
      duration: 0.2, x: -100, y: -100, delay: 0.1, ease: Power4.easeIn
    }, "close")
    .to(openTriggerSelector(".menu-trigger-bar.top"), {
      duration: 1, x: 0, y: 0, delay: 0.2, ease: Power4.easeOut
    }, "close")
    .to(openTriggerSelector(".menu-trigger-bar.middle"), {
      duration: 1, x: 0, y: 0, ease: Power4.easeOut
    }, "close")
    .to(openTriggerSelector(".menu-trigger-bar.bottom"), {
      duration: 1, x: 0, y: 0, delay: 0.1, ease: Power4.easeOut
    }, "close")
    .to(navbarContainer.current, {
      duration: 0.1, height: "12%", delay: 0.1, ease: Power4.easeOut
    });
}
  