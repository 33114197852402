import {isMobile} from 'react-device-detect';
import './FsParallaxNav.scss';
import { IFsParallaxNavProps } from './FsParallaxNav.model';

export default function FsParallaxNav(props: IFsParallaxNavProps) {
  const currentSlideNumber = props.currentSlideNumber || 0;
  return isMobile === false ?
    (
      <div id="fp-nav" className='hidden lg:block left'>
        <ul>
          { props.sections.map((_, index) => {
            const isActive = currentSlideNumber === index;
            return (
              <li key={"parallax-nav-"+index}>
                <div className={isActive ? 'active' : '' }>
                  <span></span>
                </div>
              </li>
            );
          })}
        </ul>
      </div> 
    ) 
    : <div/>;
}

