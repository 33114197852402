import { ISkillsType } from "../common/models/ISkills";

export function getSkills(): ISkillsType {
  return [
      {
        name: 'NodeJS',
        percentage: 90
      },
      {
        name: 'ReactJS',
        percentage: 90
      },
      {
        name: 'Java',
        percentage: 90
      },
      {
        name: 'Angular',
        percentage: 90
      }
  ];
}