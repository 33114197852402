import React, { useRef, useEffect } from 'react'
import { ILogoLoadingProps } from './LogoLoading.model';
import "./LogoLoading.scss";
import { gsap, Power4  } from "gsap";

const BACKGROUND_ON_OPEN = "white";

export const LogoLoading = (props: ILogoLoadingProps) => {
	const innerContainer = useRef<HTMLDivElement>(null);
	const menuTop = useRef<HTMLElement>(null);
  	const menuMiddle = useRef<HTMLElement>(null);
  	const menuBottom = useRef<HTMLElement>(null);
	const tlOpen = useRef<gsap.core.Timeline | null>(null);

	useEffect(() => {
		tlOpen.current = gsap.timeline({paused: true});

		tlOpen.current?.add("open", "0")
			.to(innerContainer.current, {
			duration: 0.8, backgroundColor: BACKGROUND_ON_OPEN, ease: Power4.easeInOut
		}, "open")
				.to(menuTop.current,  {
			duration: 0.8, x: "0", y: "0", ease: Power4.easeInOut
		}, "open")
		.to(menuMiddle.current, {
			duration: 0.8, x: "0", y: "0", ease: Power4.easeInOut
		}, "open")
		.to(menuBottom.current, {
			duration: 0.8, x: "0", y: "0", ease: Power4.easeInOut
		}, "open");
	}, [props])
	
	useEffect(() => {
		if(props.isEndLoading === true){
			tlOpen.current?.play();
		}
	}, [props.isEndLoading])
	
  return (
  	<>
		<div className='logo-loading-container flex items-center justify-center h-full w-full'>
			<div className='h-[15vh] loading-animation'
				style={{ backgroundImage: "url('/logo.png')", backgroundSize: "cover" }}>
			</div>
		</div>
		<div className='inner-container' ref={innerContainer}>
			<i className='loading-menu-bg top' ref={menuTop}></i>
			<i className='loading-menu-bg middle' ref={menuMiddle}></i>
			<i className='loading-menu-bg bottom' ref={menuBottom}></i>
		</div>
		</>
	)
}

export default LogoLoading;