import { MutableRefObject, useEffect, useRef } from 'react'
import './Navbar.scss';
import { gsap } from "gsap";
import { addCloseMenuAnimation, addOpenMenuAnimation } from './Navbar.util';
import { INavbarProps } from './Navbar.model';

const menuItems = [
  "Home",
  "About me",
  "Skills",
  "Experiences",
  "What's Next"
]

export default function Navbar(props: INavbarProps) {
  const navbarContainer = useRef<HTMLDivElement>(null);
  const openTrigger = useRef<HTMLDivElement>(null);
  const closeTrigger = useRef<HTMLDivElement>(null);
  const innerContainer = useRef<HTMLDivElement>(null);
  const menu = useRef<HTMLUListElement>(null);
  const menuTop = useRef<HTMLElement>(null);
  const menuMiddle = useRef<HTMLElement>(null);
  const menuBottom = useRef<HTMLElement>(null);
  const tlOpen = useRef<gsap.core.Timeline | null>(null);
  const tlClose = useRef<gsap.core.Timeline | null>(null);

  useEffect(() => {
    tlOpen.current = gsap.timeline({paused: true});
    tlClose.current = gsap.timeline({paused: true});
    addOpenMenuAnimation(tlOpen, navbarContainer, innerContainer, closeTrigger, openTrigger);
    addCloseMenuAnimation(tlClose, navbarContainer, innerContainer, closeTrigger, 
      openTrigger, menu, menuTop, menuMiddle, menuBottom);
  }, []);

  const handleTimelineProgress = (timeline: MutableRefObject<gsap.core.Timeline | null>) => {
    if(timeline && timeline.current){
      if(timeline.current.progress() === 0){
        timeline.current.play();
      } else {
        timeline.current.restart();
      }
    }
  }

  return (
    <>
    <div className='opacity-enter navbar-container' ref={navbarContainer}>
      <div className='logo-container'>
        <img className='h-[10vh] max-h-20' src="logo.png" alt="profile" />
      </div>
      <span className='menu-trigger' ref={openTrigger} onClick={() => handleTimelineProgress(tlOpen)}
       >
        <i className='menu-trigger-bar top'></i>
        <i className='menu-trigger-bar middle'></i>
        <i className='menu-trigger-bar bottom'></i>
      </span>
      <span className='close-trigger' ref={closeTrigger} onClick={() => handleTimelineProgress(tlClose)}>
        <i className='close-trigger-bar left'></i>
        <i className='close-trigger-bar right'></i>
      </span>
      <div className='inner-container' ref={innerContainer}>
        <i className='menu-bg top' ref={menuTop}></i>
        <i className='menu-bg middle' ref={menuMiddle}></i>
        <i className='menu-bg bottom'ref={menuBottom} ></i>
        <div className='menu-container mt-[20%] lg:mt-[10%]'>
          <ul className='menu text-3xl sm:text-5xl md:text-6xl lg:text-6xl xl:text-7xl uppercase font-extrabold' ref={menu}>
            {
              menuItems.map((menuItem, index) => 
                (
                  <li key={"nav-link-"+index}>
                    <div className={'cursor-pointer'.concat(props.page === index ? ' active' : '')}
                      onClick={()=> {props.scrollToPage(index); handleTimelineProgress(tlClose);}}>
                      {menuItem}
                    </div>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    </div>
    </>
  )
 }