import { MouseTrail } from "../common/components/mouseTrail/MouseTrail";
import { IExperiencesType } from "../common/models/IExperience";
import { ISkillsType } from "../common/models/ISkills";
import AboutMe from "../components/aboutMe/AboutMe";
import Experience from "../components/experience/Experience";
import Intro from "../components/intro/Intro";
import Skills from "../components/skills/Skills";
import WhatsNext from "../components/whatsNext/WhatsNext";

type GenericSection = {name: string, section: () => JSX.Element; }
type SkillsSection = { name: string,section: (skills: ISkillsType) => JSX.Element; }
type ExperiencesSection = { name: string,section: (experiences: IExperiencesType) => JSX.Element; }

export type SectionsConfiguration = {
  0: GenericSection;
  1: GenericSection;
  2: SkillsSection;
  3: ExperiencesSection;
  4: GenericSection;
}
export const sections: SectionsConfiguration = {
  0: { name: "Intro", section: () => <MouseTrail><Intro /></MouseTrail> },
  1: { name: "AboutMe", section: () => <AboutMe></AboutMe> },
  2: { name: "Skills", section: (skills) => <Skills skills={skills}></Skills> },
  3: { name: "Experience", section: (experiences) => <Experience experiences={experiences}></Experience> },
  4: { name: "WhatsNext", section: () => <WhatsNext></WhatsNext> },
};