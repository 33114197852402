import { TouchEvent, useState } from 'react'

const TOUCH_SENSITIVITY_SETTING = 10;

export function useSwipe(onSwipeUp?: () => void, onSwipeDown?: () => void) {

    const [touchStart, setTouchStart] = useState<number | null>(null)
    const [touchEnd, setTouchEnd] = useState<number | null>(null)

    const onTouchStart = (e: TouchEvent<HTMLElement>) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientY)
    }

    const onTouchMove = (e: TouchEvent<HTMLElement>) => setTouchEnd(e.targetTouches[0].clientY)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd;
        const isUpSwipe = distance > TOUCH_SENSITIVITY_SETTING;
        const isDownSwipe = distance < -TOUCH_SENSITIVITY_SETTING
        if(isUpSwipe) {
            onSwipeUp && onSwipeUp();
        } else if(isDownSwipe) {
            onSwipeDown && onSwipeDown();
        }
    }

    return {onTouchStart, onTouchEnd, onTouchMove};
}