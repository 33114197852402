import './FsParallaxScrollSection.scss';
import { ChangeSectionType, IFsParallaxScrollSectionProps } from './FsParallaxScrollSection.model';
import { useSwipe } from '../../hooks/useSwipe';

const SCROLL_SENSITIVITY_SETTING = 20;

export function FsParallaxScrollSection(props: IFsParallaxScrollSectionProps) {
  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe(onSwipeUp, onSwipeDown);

  function onSwipeUp(){
    props.changeSection('next')
  }

  function onSwipeDown(){
    props.changeSection('previous')
  }

  const {activeSection, section, sectionIndex, totalSections } = props;
  const scrollClassName = getClassNameFromScrollItem(sectionIndex, activeSection);
  const className = ['background', scrollClassName].join(" ");

  return (
    <section 
      className={className} 
      style={{zIndex: totalSections - sectionIndex}} 
      onWheel={(e: React.WheelEvent) => parallaxScroll(e, props.changeSection)}
      onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <div className={"content-wrapper"}>
        {section}
      </div>
    </section>
  )
}

function getClassNameFromScrollItem(sectionIndex: number, 
  activeSection: number): "down-scroll" | "up-scroll" | "" {

  if(sectionIndex === activeSection){
    return '';
  }
  if(sectionIndex < activeSection) {
    return "down-scroll";
  }
  return "up-scroll";
}

function parallaxScroll(evt: React.WheelEvent, changeSection: (changeSectionType: ChangeSectionType) => void) {
  let delta = -evt.deltaY;
  
  if (delta <= -SCROLL_SENSITIVITY_SETTING) {
    //Down scroll
    changeSection('next')
  }
  if (delta >= SCROLL_SENSITIVITY_SETTING) {
    //Up scroll
    changeSection('previous')
  }
}