import React, { useEffect, useState } from 'react'
import './FsParallaxScroll.scss';
import { IFsParallaxScrollChildrenProps, IFsParallaxScrollProps} from './FsParallaxScroll.model';
import FsParallaxNav from '../fsParallaxNav/FsParallaxNav';
import { FsParallaxScrollSection } from '../fsParallaxScrollSection/FsParallaxScrollSection';
import { ChangeSectionType } from '../fsParallaxScrollSection/FsParallaxScrollSection.model';
import { isMobile } from 'react-device-detect';

const SCROLL_DURATION_SETTING = 1500;

export function FsParallaxScroll(props: IFsParallaxScrollProps) {
  const currentSlideNumber = props.sectionSelected;
  const [isSlideLocked, setIsSlideLocked] = useState(false);

  const totalSections = props.sections.length;
  const isFirstPage = currentSlideNumber === 0;
  const isLastPage = currentSlideNumber === totalSections - 1;

  useEffect(() => {
    slideDurationTimeout(setIsSlideLocked);
  }, [isSlideLocked, props]);

  return (
    <>
      <FsParallaxNav sections={props.sections} currentSlideNumber={currentSlideNumber}></FsParallaxNav>
      <div className={
          ["parallax", ...(props.parallaxContainerClasses || [])].join(" ")
        }>
        { 
          props.sections.map((section, index) => {
            const isVisible = currentSlideNumber === index;
            const childrenProps: IFsParallaxScrollChildrenProps = {
              isVisible
            }
            const sectionCloned = React.cloneElement(
              section,
              childrenProps
            )
            return isVisible || currentSlideNumber - index === 1  || currentSlideNumber - index === -1 ?
              (
                <>
                <FsParallaxScrollSection 
                  key={"parallax-section-"+index}
                  activeSection={currentSlideNumber} section={sectionCloned}
                  sectionIndex={index} totalSections={totalSections}
                  changeSection={(changeSectionType: ChangeSectionType) => 
                    changeSection(changeSectionType, isSlideLocked, currentSlideNumber, isLastPage, props.onChangePage, 
                    setIsSlideLocked)}/>
                {
                  !isVisible || ((isMobile && !isFirstPage) || isLastPage) ? undefined 
                  : (
                    <p key={"scroll-down-section-"+index} 
                      className={"opacity-enter scrollDown text-sm xl:text-base tracking-wide leading-none "
                      .concat(isVisible ? 'transition-hide' : '')}>
                        SCROLLDOWN
                    </p>
                  )
                }
                </>
              )
              : undefined;
          })
        }
      </div>
    </>
  )
}

function changeSection(changeSectionType: ChangeSectionType, isSlideLocked: boolean, 
    currentSlideNumber: number, isLastPage: boolean, 
    onChangePage: (pageIndex: number) => void,
    setIsSlideLocked: React.Dispatch<React.SetStateAction<boolean>>) {

  if (isSlideLocked !== true) {
    setIsSlideLocked(true);
    const nextPage = getNextPage(changeSectionType, currentSlideNumber, isLastPage);
    onChangePage(nextPage)
  }
}

function getNextPage(changeSectionType: ChangeSectionType,
  currentSlideNumber: number, isLastPage: boolean): number {

  if (changeSectionType === "next") {
    //Down scroll
    return nextItem(currentSlideNumber, isLastPage);
  }
  else {
    //Up scroll
    return previousItem(currentSlideNumber);
  }
}

function slideDurationTimeout(setIsSlideLocked: React.Dispatch<React.SetStateAction<boolean>>) {
  setTimeout(() => setIsSlideLocked(false), SCROLL_DURATION_SETTING);
}

function nextItem(currentSlideNumber: number, isLastPage: boolean) {
  if(isLastPage === false){
    return currentSlideNumber + 1;
  }
  return isLastPage ? currentSlideNumber : currentSlideNumber + 1;
}

function previousItem(currentSlideNumber: number = 0) {
  return currentSlideNumber > 0 ? currentSlideNumber - 1: currentSlideNumber;
}