import './BigTitle.scss';
import { IBigTitleProps } from './BigTitle.model';

export default function BigTitle (props: IBigTitleProps) {

  const {title} = props;
  return (
    <>
      <div className='big-title text-[7rem] sm:text-[7rem] md:text-[10rem] lg:text-[12rem] xl:text-[15rem]
          absolute -bottom-[6%] xl:-bottom-[12vh] 
          -right-10 lg:-right-10 whitespace-nowrap overflow-x-hidden uppercase max-w-[100vw] font-semibold'>
          {title}
      </div>
    </>
  )
}
