import React from "react";
import Canvas from "../canvas/Canvas";
import { IMouseTrailState, Particle, V2 } from "./MouseTrail.model";
import { IMouseTrailCanvasProps } from "./MouseTrailCanvas.model";

// INIT
const initForces = (width: number, height: number) => {
  var i = 0;
  const forces = [];
  for (var x = 0; x < width; x+=20) {
    for (var y = 0; y < height; y+=20) {
      if (!forces[i]) forces[i] = new V2();
      i++;
    }
  }
  if (i < forces.length) {
    forces.splice(i+1);
  }
  return forces;
}

const initParticles = () => {
  const particles: Particle[] = [];
  for (var i = 0; i < nParticles; i++) {
    particles.push(new Particle(0.1));
  }
  return particles;
}

const nParticles = 250;
const width = window.innerWidth;
const height = window.innerHeight;

const mouseTrailState = {
	particles: initParticles(),
	forces: initForces(width, height),
	emitter: new V2(window.innerWidth/2, window.innerHeight/2),
};

export function MouseTrailCanvas(props: IMouseTrailCanvasProps){
  
	return (
		<Canvas 
			draw={getDraw(props, mouseTrailState)} 
			options={{context: '2d'}} 
      isVisible={props.isVisible}/>
	)
}

function getDraw(props: IMouseTrailCanvasProps, mouseTrailState: IMouseTrailState) {

	return (ctx: CanvasRenderingContext2D, t: DOMHighResTimeStamp) => {
    var rect = ctx.canvas.getBoundingClientRect();
    const mouse = new V2(props.mouse.x - rect.left, props.mouse.y - rect.top);
		props.animation(t, ctx, mouse, mouseTrailState);
	}
}