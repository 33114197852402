import React from 'react'
import './AboutMe.scss';
import { IAboutMeProps } from './AboutMe.model';
import Paragraph from '../../common/components/paragraph/Paragraph';
import BigTitle from '../../common/components/bigTitle/BigTitle';

export default function AboutMe (props: IAboutMeProps) {
  return (
    <>
    <div className="container">
      <BigTitle title='This is me'></BigTitle>
      <div className='flex flex-wrap-reverse lg:flex-nowrap lg:space-x-16 
        items-center justify-center h-full p-10 sm:p-20 lg:p-28 lg:pl-40'>
        <div className='lg:basis-6/12 self-end lg:self-center'>
          <Paragraph title='THIS IS ME'>
            I'm passionated about solving problems with innovative, scalable and high quality solutions.
            <br></br><br></br>
            I enjoy to test my self with new challenges, reaching new professional goals.
          </Paragraph>
        </div>
        <div className='sm:mb-0 lg:basis-5/12 items-center'>
          <img className='w-60 md:w-96 mx-auto object-center' src="profile.png" alt="profile" />
        </div>
      </div>
    </div>
    </>
  )
}
