import './Paragraph.scss';
import { IParagraphProps } from './Paragraph.model';

export default function Paragraph (props:IParagraphProps) {

  const {title, titleClasses} = props;
  return (
    <>
      <div className={
        [
          'text-sm md:text-2xl lg:text-lg font-normal tracking-widest paragraph', 
          ...(titleClasses || [])
        ].join(" ")
      }>
        / {title}
      </div>
      <div className='text-base sm:text-3xl md:text-4xl lg:text-2xl xl:text-3xl md:text-3xl font-normal tracking-widest'>
        {props.children}
      </div>
    </>
  )
}
