import React, { useState } from "react";
import { IFsParallaxScrollChildrenProps } from "../fsParallaxScroll/FsParallaxScroll.model";
import { IMouseTrailState, Particle, V2 } from "./MouseTrail.model";
import "./MouseTrail.scss";
import { MouseTrailCanvas } from "./MouseTrailCanvas";
import { IMouseTrailCanvasState } from "./MouseTrailCanvas.model";
import * as noise from "./perlin.js";

let p = 0;
const nParticles = 250;
const width = window.innerWidth;
const height = window.innerHeight;
noise.seed(Math.random());

export function MouseTrail(props: IFsParallaxScrollChildrenProps) {
  const [mouseTrailConfig, setMouseTrailConfig] = useState<IMouseTrailCanvasState>({
    animation: () => {},
    mouse: new V2(window.innerWidth/2, window.innerHeight/2)
  });  
  
  return <>
    <div id="mouse-trail" 
      onTouchMove={(event: React.TouchEvent<HTMLDivElement>) => 
        setMouseTrailConfig({
        animation: animate,
        mouse: new V2(event.touches.item(0).pageX, event.touches.item(0).pageY)
      })}
      onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => 
        setMouseTrailConfig({
          animation: animate,
          mouse: new V2(event.pageX, event.pageY)
        })}>
      <MouseTrailCanvas 
        animation={mouseTrailConfig.animation} 
        mouse={mouseTrailConfig.mouse} isVisible={props.isVisible ?? false}/>
      {props.children}
    </div>
  </> 
}

// UPDATE
const updateForces = (t: DOMHighResTimeStamp, width: number, height: number, forces: V2[]) => {
  var i = 0;
  var xOff = 0, yOff = 0;
  for (var x = 0; x < width; x+=20) {
    xOff += 0.1;
    for (var y = 0; y < height; y+=20) {
      yOff += 0.1;
      let a = noise.perlin3(xOff, yOff, t*0.00005) * Math.PI * 4;
      if (forces[i]) forces[i].reset(Math.cos(a)*0.1, Math.sin(a)*0.1);
      i++;
    }
  }
}

const drawParticles = (ctx: CanvasRenderingContext2D, heigth:number, emitter: V2, forces: V2[], 
  particles: Particle[]) => {
    
  launchParticle(emitter, particles);
  
  for (var i = 0; i < nParticles; i++) {
    particles[i].update();
    particles[i].follow(heigth, forces);
    particles[i].draw(ctx);
  }
}

const launchParticle = (emitter: V2, particles: Particle[]) => {

  particles[p].position.reset(emitter.x, emitter.y);
  particles[p].velocity.reset(-1+ Math.random()*2, -1+Math.random()*2);
  particles[p].color = `#318b99`;
  particles[p].alpha = 1;
  p++;
  if (p === nParticles) p = 0;
}

const updateEmitter = (mouse: V2, emitter: V2) => {
  emitter.lerp(mouse, 0.2);
}

const animate = (t: DOMHighResTimeStamp, ctx: CanvasRenderingContext2D, mouse: V2, 
  mouseTrailState: IMouseTrailState) => {

  ctx.clearRect(0, 0, width, height);
  updateEmitter(mouse, mouseTrailState.emitter);
  updateForces(t, width, height, mouseTrailState.forces);
  drawParticles(ctx, height, mouseTrailState.emitter,  mouseTrailState.forces, mouseTrailState.particles);
}
