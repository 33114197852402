import './Intro.scss';
import { IIntroProps } from './Intro.model';

export default function Intro (props: IIntroProps) {
  
  return (
    <div className='flex items-center justify-center h-full'>
      <div className='opacity-enter flex-col space-y-4 
        text-xl sm:text-5xl xl:text-6xl font-medium text-center tracking-[.10em] lg:tracking-[.20em]'>
          <h1>HI, I'M MARCO.</h1>
          <h1>A SOFTWARE ENGINEER</h1>
          <h1>BASED IN ITALY</h1>   
      </div>
    </div>
  );
}
