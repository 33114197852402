import './Skills.scss';
import { ISkillsMeProps } from './Skills.model';
import Paragraph from '../../common/components/paragraph/Paragraph';
import BigTitle from '../../common/components/bigTitle/BigTitle';

export default function Skills (props: ISkillsMeProps) {
 
  const isVisible = props.isVisible;
  return (
    <>
    <div className='container'>
      <BigTitle title='SKILLS'></BigTitle>
      <div className='flex flex-wrap lg:flex-nowrap lg:space-x-16 
        items-center justify-center h-full p-10 sm:p-20 lg:p-28 lg:pl-40'>
        <div className='lg:basis-5/12 self-end lg:self-center'>
          <Paragraph title='SKILLS'>
            I started learning to code when I was a teenager and I’m improving my knowledges everyday.
            <br></br><br></br>
            I worked on multiple projects for multiple clients in Financial, Industry and Telco sectors. 
          </Paragraph>
        </div>
        <div className='lg:basis-6/12 w-full'>
          <div className='skills-bar'>
            {
              props.skills.map((skillItem, index) => (
                <div key={"skill-"+index} className='bar'>
                  <div className='info text-sm sm:text-xl md:text-xl lg:text-xl'>
                      <span>{skillItem.name}</span>
                  </div>
                  <div className='sm:w-full progress-line'>
                      <span 
                        className={ isVisible? 'animation': '' }
                        style={{width: skillItem.percentage+'%'}}>  
                      </span>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

