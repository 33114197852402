import { useEffect, useState } from 'react';
import './App.scss';
import { FsParallaxScroll } from './common/components/fsParallaxScroll/FsParallaxScroll';
import Navbar from './common/components/navbar/Navbar';
import LogoLoading from './common/components/logoLoading/LogoLoading';
import { ISkillsType } from './common/models/ISkills';
import { IExperiencesType } from './common/models/IExperience';
import { getExperiences } from './services/Experience.service';
import { getSkills } from './services/Skills.service';
import { trackPageVisitedIntoWebAnalytics } from './services/WebAnalytics.service';
import { sections, SectionsConfiguration } from './configs/SectionsConfig';

function App() {

  const [currentPage, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [skills, setSkills] = useState<ISkillsType>([]);
  const [experiences, setExperiences] = useState<IExperiencesType>([]);

  useEffect(() => {
    setTimeout(() => {
      setExperiences(getExperiences());
      setSkills(getSkills());
      setIsLoading(false)
    }, 0);
  }, []);

  useEffect(() => {
    const sectionNameFound = sections[currentPage as keyof SectionsConfiguration]?.name;
    /*
    if(sectionNameFound !== undefined){
      trackPageVisitedIntoWebAnalytics(sections[currentPage as keyof SectionsConfiguration].name);
    }*/
  }, [currentPage]);
  
  return (
    <>
    {
      isLoading ? <LogoLoading isEndLoading={!isLoading}/> : (
        <>
        <Navbar scrollToPage={(pageIndex: number) => setPage(pageIndex)} page={currentPage} />
        <FsParallaxScroll
          onChangePage={(pageIndex: number) => setPage(pageIndex)}
          sectionSelected={currentPage}
          sections={[
            sections[0].section(),
            sections[1].section(),
            sections[2].section(skills),
            sections[3].section(experiences),
            sections[4].section()
          ]} />
        </>
      )
    }
    </>
  );
}
export default App;
